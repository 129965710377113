<template>
  <div style="height: 100vh;">
    <q-dialog v-model="confirm" persistent>
      <q-card class="q-py-md q-px-sm">
        <q-card-section>
          Apakah Anda akan keluar dari halaman survey siswa?
        </q-card-section>
        <q-card-section class="row justify-evenly">
          <q-btn
            dense
            outline
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="confirm = false"
            label="Tidak"
          />
          <q-btn
            dense
            unelevated
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="cancelSurvey"
            label="Ya"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="confirmBerhalangan" persistent>
      <q-card class="q-py-md q-px-sm">
        <q-card-section>
          Apakah <u>{{ selectSiswa.nama }}</u> tidak memungkinkan untuk
          disurvey?
        </q-card-section>
        <q-card-section class="row justify-evenly">
          <q-btn
            dense
            outline
            color="indigo-9"
            style="width: 80px;"
            no-caps
            v-close-popup
            label="Tidak"
            @click="
              selectSiswa.berhalangan = false;
              selectSiswa.disabled = false;
              selectSiswa.selected = true;
            "
          />
          <q-btn
            dense
            unelevated
            color="indigo-9"
            style="width: 80px;"
            no-caps
            v-close-popup
            @click="
              selectSiswa.berhalangan = true;
              selectSiswa.disabled = true;
              selectSiswa.selected = false;
            "
            label="Ya"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <div
      class="column no-wrap justify-between"
      style="height: 100%;"
      v-if="!onSurvey"
    >
      <div class="row items-center absolute-top-right q-mr-md">
        <div class="text-grey q-pr-xs">{{ surveyor.nama }}</div>
        |
        <q-btn
          dense
          flat
          @click="confirm = true"
          color="red"
          label="logout"
          no-caps
        />
      </div>
      <div class="col-auto top q-pa-md q-pt-xl">
        <div class=" q-py-sm">
          <!-- <div style="color: grey; font-size: 0.8em;">Pilih gedung</div> -->
          <q-select
            label="gedung"
            stack-label
            placeholder="Nama Gedung"
            dense
            class="bg-grey-2 text-black no-border q-pb-sm"
            square
            outlined
            style="overflow:hide;"
            color="bg-grey-1"
            v-model="selectGedung"
            :options="listGedung"
          ></q-select>
          <!-- <div style="color: grey; font-size: 0.8em;">Input nomor kamar</div> -->
          <q-input
            label="nomer kamar"
            stack-label
            square
            outlined
            color="bg-grey-1"
            v-model="noRoom"
            dense
            class="bg-grey-2 text-black no-border "
          >
            <template v-slot:append>
              <q-btn
                flat
                label="cari"
                class="bg-positive text-white absolute-right"
                style="border-radius: 0px;"
                @click="getListSiswa"
              />
            </template>
          </q-input>
        </div>
        <div style="color: grey; font-size: 0.8em;">
          hilangkan checklist pada siswa yang tidak hadir
        </div>
      </div>
      <div class="col-auto middle">
        <div
          v-for="x in siswa"
          :key="x.no_induk"
          class="q-py-xs"
          v-touch-hold.mouse="(event) => siswaBerhalangan(x, event)"
        >
          <q-checkbox
            v-model="x.selected"
            size="lg"
            :label="x.nama"
            color="positive"
            :disable="x.disabled"
            :class="'ellipsis ' + (x.disabled ? 'text-strike' : '')"
          />
          <q-btn
            dense
            v-show="x.berhalangan"
            flat
            icon="home"
            color="negative"
          />
          <q-btn
            dense
            v-show="x.karantina"
            flat
            icon="masks"
            color="negative"
          />
        </div>
      </div>
      <div class="col-2 bottom row items-end">
        <q-btn
          unelevated
          label="Mulai Survey"
          color="indigo-9"
          class="full-width q-pa-sm no-border-radius"
          @click="startSurvey"
          :disable="!canStart"
        />
        <!-- v-if="canStart" -->
        <!-- <q-btn 
          outline
          label="Keluar"
          color="red"
          class="full-width q-pa-sm no-border-radius"
          @click="cancelSurvey"
          v-else
        /> -->
      </div>
    </div>
    <div v-else>
      <div
        v-if="step == 1"
        class="column no-wrap justify-between"
        style="height: 100vh;"
      >
        <div class="row items-center absolute-top-right q-mr-md">
          <div class="text-grey q-pr-xs">{{ surveyor.nama }}</div>
          |
          <q-btn
            dense
            flat
            @click="confirm = true"
            color="red"
            label="logout"
            no-caps
          />
        </div>
        <div class="col-auto q-pa-md q-pt-xl top">
          <div style="color: grey; font-size: 0.8em;">pertanyaan 1 dari 5</div>
          <div class="q-py-md">
            1. Mengalami gejala Flu,Demam dan Batuk dalam 7 hari terakhir?
          </div>
          <div style="color: grey; font-size: 0.8em;">
            berikan checklist pada siswa yang menyatakan ya
          </div>
        </div>
        <div class="col-auto middle">
          <div v-for="x in siswa" :key="x.no_induk" class="q-py-xs">
            <q-checkbox
              v-model="x.Q1"
              val="Y"
              size="lg"
              :label="x.nama"
              color="positive"
              :disable="!x.selected"
              :class="'ellipsis ' + (!x.selected ? 'text-strike' : '')"
            />
            <q-btn
              dense
              v-show="x.berhalangan"
              flat
              icon="home"
              color="negative"
            />
            <q-btn
              dense
              v-show="x.karantina"
              flat
              icon="masks"
              color="negative"
            />
          </div>
        </div>
        <div class="col-2 bottom  row items-end">
          <div class="row" style="width: 100%;">
            <q-btn
              outline
              class="col-6 q-pa-sm  no-border-radius"
              label="Kembali"
              color="indigo-10"
              @click="onSurvey = false"
            />
            <q-btn
              class="col-6 q-pa-sm no-border-radius"
              label="Lanjut"
              color="indigo-10"
              @click="step = 2"
            />
          </div>
        </div>
      </div>
      <div
        v-if="step == 2"
        class="column no-wrap justify-between"
        style="height: 100vh;"
      >
        <div class="row items-center absolute-top-right q-mr-md">
          <div class="text-grey q-pr-xs">{{ surveyor.nama }}</div>
          |
          <q-btn
            dense
            flat
            @click="confirm = true"
            color="red"
            label="logout"
            no-caps
          />
        </div>
        <div class="col-auto q-pa-md q-pt-xl top">
          <div style="color: grey; font-size: 0.8em;">pertanyaan 2 dari 5</div>
          <div class="q-py-md">
            2. Mengalami diare dalam 7 hari terakhir?
          </div>
          <div style="color: grey; font-size: 0.8em;">
            berikan checklist pada siswa yang menyatakan ya
          </div>
        </div>
        <div class="col-auto middle">
          <div v-for="x in siswa" :key="x.no_induk" class="q-py-xs">
            <q-checkbox
              v-model="x.Q2"
              val="Y"
              size="lg"
              :label="x.nama"
              color="positive"
              :disable="!x.selected"
              :class="'ellipsis ' + (!x.selected ? 'text-strike' : '')"
            />
            <q-btn
              dense
              v-show="x.berhalangan"
              flat
              icon="home"
              color="negative"
            />
            <q-btn
              dense
              v-show="x.karantina"
              flat
              icon="masks"
              color="negative"
            />
          </div>
        </div>
        <div class="col-2 bottom  row items-end">
          <div class="row" style="width: 100%;">
            <q-btn
              outline
              class="col-6 q-pa-sm  no-border-radius"
              label="Kembali"
              color="indigo-10"
              @click="step = 1"
            />
            <q-btn
              class="col-6 q-pa-sm no-border-radius"
              label="Lanjut"
              color="indigo-10"
              @click="step = 3"
            />
          </div>
        </div>
      </div>
      <div
        v-if="step == 3"
        class="column  no-wrap justify-between"
        style="height: 100vh;"
      >
        <div class="row items-center absolute-top-right q-mr-md">
          <div class="text-grey q-pr-xs">{{ surveyor.nama }}</div>
          |
          <q-btn
            dense
            flat
            @click="confirm = true"
            color="red"
            label="logout"
            no-caps
          />
        </div>
        <div class="col-auto q-pa-md q-pt-xl top">
          <div style="color: grey; font-size: 0.8em;">pertanyaan 3 dari 5</div>
          <div class="q-py-md">
            3. Mengalami kehilangan fungsi indra penciuam, dan indra perasa
            dalam 7 hari terakhir?
          </div>
          <div style="color: grey; font-size: 0.8em;">
            berikan checklist pada siswa yang menyatakan ya
          </div>
        </div>
        <div class="col-auto middle">
          <div v-for="x in siswa" :key="x.no_induk" class="q-py-xs">
            <q-checkbox
              v-model="x.Q3"
              val="Y"
              size="lg"
              :label="x.nama"
              color="positive"
              :disable="!x.selected"
              :class="'ellipsis ' + (!x.selected ? 'text-strike' : '')"
            />
            <q-btn
              dense
              v-show="x.berhalangan"
              flat
              icon="home"
              color="negative"
            />
            <q-btn
              dense
              v-show="x.karantina"
              flat
              icon="masks"
              color="negative"
            />
          </div>
        </div>
        <div class="col-2 bottom row items-end">
          <div class="row" style="width: 100%;">
            <q-btn
              outline
              class="col-6 q-pa-sm no-border-radius"
              label="Kembali"
              color="indigo-10"
              @click="step = 2"
            />
            <q-btn
              class="col-6 q-pa-sm no-border-radius"
              label="Lanjut"
              color="indigo-10"
              @click="step = 4"
            />
          </div>
        </div>
      </div>
      <div
        v-if="step == 4"
        class="column no-wrap justify-between"
        style="height: 100vh;"
      >
        <div class="row items-center absolute-top-right q-mr-md">
          <div class="text-grey q-pr-xs">{{ surveyor.nama }}</div>
          |
          <q-btn
            dense
            flat
            @click="confirm = true"
            color="red"
            label="logout"
            no-caps
          />
        </div>
        <div class="col-auto q-pa-md q-pt-xl top">
          <div style="color: grey; font-size: 0.8em;">pertanyaan 4 dari 5</div>
          <div class="q-py-md">
            4. Pernah tidak menggunakan masker saat keluar Asrama, ataupun
            ketika dalam melaksanakan aktifitas?
          </div>
          <div style="color: grey; font-size: 0.8em;">
            berikan checklist pada siswa yang menyatakan ya
          </div>
        </div>
        <div class="col-auto middle">
          <div v-for="x in siswa" :key="x.no_induk" class="q-py-xs">
            <q-checkbox
              v-model="x.Q4"
              val="Y"
              size="lg"
              :label="x.nama"
              color="positive"
              :disable="!x.selected"
              :class="'ellipsis ' + (!x.selected ? 'text-strike' : '')"
            />
            <q-btn
              dense
              v-show="x.berhalangan"
              flat
              icon="home"
              color="negative"
            />
            <q-btn
              dense
              v-show="x.karantina"
              flat
              icon="masks"
              color="negative"
            />
          </div>
        </div>
        <div class="col-2 bottom  row items-end">
          <div class="row" style="width: 100%;">
            <q-btn
              outline
              class="col-6 q-pa-sm no-border-radius"
              label="Kembali"
              color="indigo-10"
              @click="step = 3"
            />
            <q-btn
              class="col-6 q-pa-sm no-border-radius"
              label="Lanjut"
              color="indigo-10"
              @click="step = 5"
            />
          </div>
        </div>
      </div>
      <div
        v-if="step == 5"
        class="column no-wrap justify-between"
        style="height: 100vh;"
      >
        <div class="row items-center absolute-top-right q-mr-md">
          <div class="text-grey q-pr-xs">{{ surveyor.nama }}</div>
          |
          <q-btn
            dense
            flat
            @click="confirm = true"
            color="red"
            label="logout"
            no-caps
          />
        </div>
        <div class="col-auto q-pa-md q-pt-xl top">
          <div style="color: grey; font-size: 0.8em;">pertanyaan 5 dari 5</div>
          <div class="q-py-md">
            5. Mengalami demam lebih dari 37.2&#176;C dalam 3 hari terakhir?
          </div>
          <div style="color: grey; font-size: 0.8em;">
            berikan checklist pada siswa yang menyatakan ya
          </div>
        </div>
        <div class="col-auto middle">
          <div v-for="x in siswa" :key="x.no_induk" class="q-py-xs">
            <q-checkbox
              v-model="x.Q5"
              val="Y"
              size="lg"
              :label="x.nama"
              color="positive"
              :disable="!x.selected"
              :class="'ellipsis ' + (!x.selected ? 'text-strike' : '')"
            />
            <q-btn
              dense
              v-show="x.berhalangan"
              flat
              icon="home"
              color="negative"
            />
            <q-btn
              dense
              v-show="x.karantina"
              flat
              icon="masks"
              color="negative"
            />
          </div>
        </div>
        <div class="col-2 bottom  row items-end">
          <div class="row" style="width: 100%;">
            <q-btn
              outline
              class="col-6 q-pa-sm no-border-radius"
              label="Kembali"
              color="indigo-10"
              @click="step = 4"
            />
            <q-btn
              class="col-6 q-pa-sm no-border-radius"
              label="Submit"
              color="indigo-10"
              @click="onSubmit"
            />
          </div>
        </div>
      </div>
      <div
        v-if="step == 6"
        class="column no-wrap justify-between"
        style="height: 100vh;"
      >
        <div class="row items-center absolute-top-right q-mr-md">
          <div class="text-grey q-pr-xs">{{ surveyor.nama }}</div>
          |
          <q-btn
            dense
            flat
            @click="confirm = true"
            color="red"
            label="logout"
            no-caps
          />
        </div>
        <div class="col-auto q-pa-md q-pt-xl top text-center">
          <div class="text-h6">
            HASIL SURVEY
          </div>
          <div>survey dilakukan oleh: {{ surveyor.nama }}</div>
        </div>
        <div class="col-auto q-pa-md middle">
          <div v-for="x in siswa" :key="x.no_induk">
            <div
              :style="
                x.selected
                  ? x.nilai_survey > 30
                    ? 'color: red'
                    : ''
                  : 'color: grey'
              "
              class="row q-py-sm"
            >
              <p
                :style="
                  'padding-right: 5px; top: -0.6em;font-size: .83em; ' +
                    (x.nilai_survey > 30 ? '' : 'color:green')
                "
              >
                {{ x.selected || x.berhalangan ? x.nilai_survey : "-" }}
              </p>
              <p :class="'ellipsis ' + (!x.selected ? 'text-strike' : '')">
                {{ x.nama }}
              </p>
              <q-btn
                dense
                v-show="x.berhalangan"
                flat
                icon="home"
                color="negative"
              />
              <q-btn
                dense
                v-show="x.karantina"
                flat
                icon="masks"
                color="negative"
              />
            </div>
          </div>
        </div>
        <div class="col-2 bottom  row items-end">
          <div class="row" style="width: 100%;">
            <q-btn
              unelevated
              class="full-width q-pa-sm  no-border-radius"
              label="Selesai"
              color="indigo-10"
              @click="
                onSurvey = false;
                siswa = [];
                noRoom = '';
                step = 1;
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "App",

  data() {
    return {
      selectSiswa: { nama: "" },
      confirmBerhalangan: false,
      surveyor: {},
      noRoom: "",
      siswa: [],
      onSurvey: false,

      step: 1,
      confirm: false,
      listGedung: [],
      selectGedung: "",
    };
  },
  mounted() {
    this.$http.get("/list_gedung", {}).then((result) => {
      result.data.forEach((element) => {
        if (element.gedung.length > 0) this.listGedung.push(element.gedung);
      });
      this.selectGedung = this.listGedung[0];
    });
    this.$http.get("/kode_unik", {}).then((result) => {
      if (localStorage.getItem("kode_unik") != result.data.kode_unik) {
        this.$router.push("/");
      }
    });
    this.$http
      .get("/guru_by_noinduk/" + localStorage.getItem("surveyor"), {})
      .then((result) => {
        this.surveyor = result.data;
      });
  },
  methods: {
    siswaBerhalangan(user, evnt) {
      if (user.karantina) return;
      this.selectSiswa = user;
      this.confirmBerhalangan = true;
    },
    getListSiswa() {
      this.siswa = [];
      this.$http
        .get("/list_siswa/" + this.noRoom + "/" + this.selectGedung, {})
        .then((result) => {
          for (var i = 0; i < result.data.length; i++) {
            let data = result.data[i];
            data.Q1 = false;
            data.Q2 = false;
            data.Q3 = false;
            data.Q4 = false;
            data.Q5 = false;
            data.nilai_survey = 0;

            if (result.data[i].status == 0) {
              data.selected = true;
              data.disabled = false;
              data.berhalangan = false;
              data.karantina = false;
            } else if (result.data[i].status == 1) {
              data.selected = false;
              data.disabled = true;
              data.berhalangan = false;
              data.karantina = false;
            } else if (result.data[i].status == 2) {
              // WIP
              data.selected = false;
              data.disabled = true;
              data.berhalangan = false;
              data.karantina = true;
            }

            this.siswa.push(data);
          }
        });
    },
    startSurvey() {
      this.onSurvey = true;
    },
    cancelSurvey() {
      localStorage.removeItem("kode_unik");
      localStorage.removeItem("surveyor");
      this.$router.push("/");
    },
    onSubmit() {
      this.step = 6;
      for (var i = 0; i < this.siswa.length; i++) {
        let val =
          (this.siswa[i].Q1 ? 10 : 0) +
          (this.siswa[i].Q2 ? 10 : 0) +
          (this.siswa[i].Q3 ? 10 : 0) +
          (this.siswa[i].Q4 ? 10 : 0) +
          (this.siswa[i].Q5 ? 10 : 0);
        if (this.siswa[i].selected || this.siswa[i].berhalangan) {
          var data = {
            gedung: this.siswa[i].gedung,
            no_kamar: this.siswa[i].no_kamar,
            no_induk: this.siswa[i].no_induk,
            Q1: this.siswa[i].Q1 ? "Y" : "N",
            Q2: this.siswa[i].Q2 ? "Y" : "N",
            Q3: this.siswa[i].Q3 ? "Y" : "N",
            Q4: this.siswa[i].Q4 ? "Y" : "N",
            Q5: this.siswa[i].Q5 ? "Y" : "N",
            nilai_survey: val,
            surveyor: this.surveyor.nama,
          };
          if (val > 30) {
            data.karantina = "Y";
            data.tgl_karantina = moment().format("YYYY-MM-DD");
          }
          this.$http.post("/new_survey/", data, {}).then((result) => {});
        }
        this.siswa[i].nilai_survey = val;
      }
    },
  },
  computed: {
    canStart() {
      let selected = this.siswa.some((data) => data["selected"] === true);

      return selected;
    },
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Montserrat";
  src: url("~@/assets/fonts/Montserrat-Medium.otf") format("truetype");
}

* {
  font-family: "Montserrat";
}

.top {
  // min-height: 100px;
  // padding: 5px 18px;
  // overflow-y: auto;
}
.middle {
  background-color: rgb(230, 230, 230);
  // padding: 10px;
  overflow: auto;
  flex-grow: 99;
}
.bottom {
  // height: 100px;
}
</style>
